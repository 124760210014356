import { sendGTMEvent } from '@next/third-parties/google';
import { FNCLineItem } from 'shared/types/cart/LineItem';
import { FNCProduct } from 'shared/types/product/Product';
import { getProductTypeAttributeValue } from 'shared/utils/get-product-type-attribute-value';

export const sendGA4Event = (
  name: string,
  param_1_name?: string,
  param_1_value?: string,
  param_2_name?: string,
  param_2_value?: string,
  param_3_name?: string,
  param_3_value?: string,
  param_4_name?: string,
  param_4_value?: string,
  param_5_name?: string,
  param_5_value?: string,
) => {
  sendGTMEvent({ ga4_event_parameters: null });
  sendGTMEvent({
    event: 'GAEvent',
    ga4_event_name: name,
    ga4_event_parameters: {
      ...(param_1_name && { param_1_name: param_1_name }),
      ...(param_1_value && { param_1_value: param_1_value }),
      ...(param_2_name && { param_2_name: param_2_name }),
      ...(param_2_value && { param_2_value: param_2_value }),
      ...(param_3_name && { param_3_name: param_3_name }),
      ...(param_3_value && { param_3_value: param_3_value }),
      ...(param_4_name && { param_4_name: param_4_name }),
      ...(param_4_value && { param_4_value: param_4_value }),
      ...(param_5_name && { param_5_name: param_5_name }),
      ...(param_5_value && { param_5_value: param_5_value }),
    },
  });
};

type GA4LineItem = FNCProduct | FNCLineItem;

export const sendGA4TransactionEvent = (name: string, items: GA4LineItem[], transactionId?: string) => {
  if (items.length === 0) {
    return;
  }

  let total = 0;
  const lineItems = items.map((product, index) => {
    // Use the selected variant if it's a product, otherwise use the variant from the line item
    const variant =
      'selectedVariant' in product ? (product as FNCProduct).selectedVariant : (product as FNCLineItem).variant;

    // Use the discounted price if it exists, otherwise use the regular price
    const price_to_use = variant.discountedPrice ? variant.discountedPrice : variant.price;

    // Get the price in the correct format
    const price =
      price_to_use && price_to_use.centAmount ? price_to_use.centAmount / 10 ** (price_to_use?.fractionDigits || 0) : 0;

    // Get quantity
    const quantity = (product as FNCLineItem)?.count ?? 1;

    // Add the subtotal to the total
    total += price * quantity;

    // Get the product type attribute value
    const item_variant = getProductTypeAttributeValue(variant.attributes?.['options_colour'] ?? '') as string;

    // Get the item category
    const item_category = variant.attributes?.['descriptors_reporting-group2']?.key ?? '';

    return {
      item_id: variant.sku,
      item_name: product.name,
      affiliation: 'Online',
      coupon: null,
      discount: null,
      currency: variant.price?.currencyCode,
      index,
      item_brand: 'Furniture And Choice',
      item_variant: item_variant,
      item_category,
      item_category2: null,
      price,
      quantity,
    };
  });

  sendGTMEvent({
    event: name,
    transaction_id: transactionId,
    currency: lineItems?.[0].currency,
    value: total,
    items: lineItems,
  });
};
