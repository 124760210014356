import { useContext } from 'react';
import { FNCProduct } from 'shared/types/product/Product';
import useSWR, { SWRResponse } from 'swr';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { sdk } from '~/sdk';

interface UseProductArgs {
  sku: string;
}

export default function useProduct({ sku }: UseProductArgs): SWRResponse<FNCProduct> {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);

  return useSWR<FNCProduct>(`/action/furniturechoice-product/getProductBySku/${sku}`, async () => {
    return await executeWithProgress<FNCProduct>(async () => {
      const response = await sdk.callAction<FNCProduct>({
        actionName: 'furniturechoice-product/getProductBySku',
        payload: { sku },
        skipQueue: true,
      });

      if (response.isError) {
        throw response.error;
      }

      return response.data;
    });
  });
}
