'use client';

import { useParams } from 'next/navigation';
import { SDKResponse } from '@commercetools/frontend-sdk';
import Toaster from 'components/commercetools-ui/atoms/toaster';
import { sdk } from 'sdk';
import { Translations } from 'types/i18n';
import { MinicartProvider } from '~/helpers/contexts/furniturechoice/minicart';
import i18nConfig from '~/i18n.config';
import I18nProvider from './i18n';
import { SWRProvider } from './swr';
import { GetAccountActionReturn } from '../sdk/composable-commerce/types/actions/AccountActions';

interface ProvidersProps {
  translations: Translations;
  accountResult?: SDKResponse<GetAccountActionReturn>;
}

export const Providers = ({ translations, accountResult, children }: React.PropsWithChildren<ProvidersProps>) => {
  const { paramLocale } = useParams();
  const locale = paramLocale && Array.isArray(paramLocale) ? paramLocale[0] : paramLocale || i18nConfig.defaultLocale;

  sdk.defaultConfigure(locale);

  return (
    <I18nProvider translations={translations}>
      <SWRProvider
        value={{
          fallback: { '/action/account/getAccount': accountResult },
          shouldRetryOnError: (error) => {
            if (error.message.toLowerCase().includes('not found')) return false;
            return true;
          },
        }}
      >
        <MinicartProvider>{children}</MinicartProvider>
        <Toaster />
      </SWRProvider>
    </I18nProvider>
  );
};
