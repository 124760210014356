import { RefObject, useEffect, useState } from 'react';

interface Args {
  ref?: RefObject<HTMLElement>;
  options?: IntersectionObserverInit;
}

export default function useIsInView({ ref, options = { threshold: 0 } }: Args) {
  const [isInView, setIsInView] = useState<boolean>(false);
  const [hasBeenViewed, setHasBeenViewed] = useState<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);

      if (entry.isIntersecting && !hasBeenViewed) {
        setHasBeenViewed(true);
      }
    }, options);

    const currentElement = ref?.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [hasBeenViewed, options, ref]);

  return { isInView, hasBeenViewed };
}
