import CoFeImage from '~/components/furniturechoice/co-fe-image';
import Link from '~/components/furniturechoice/link';
import { SecondarySubNavigation } from '../../../../types';

interface SecondaryProps {
  subNavigations: SecondarySubNavigation[];
}

export default function Secondary({ subNavigations }: SecondaryProps): JSX.Element {
  return (
    <div className="container">
      <div className="grid grid-cols-4 gap-9 px-3 xl:grid-cols-5">
        {subNavigations.map((subNavigation) => (
          <div key={subNavigation.label} className="flex flex-col gap-y-4">
            <div className="flex h-11 w-11 items-center justify-center overflow-hidden rounded-full bg-grey-2">
              <CoFeImage className="aspect-square object-contain" asset={subNavigation.image.media} sizes="44px" />
            </div>

            <p className="text-18 font-bold">{subNavigation.label}</p>

            <ul className="flex flex-col gap-y-2">
              {subNavigation.links.map((link) => (
                <li key={link.label}>
                  <Link
                    className="color-change-transition text-14 hover:text-salmon-5"
                    reference={link.reference}
                    ga4_event_name="navigation_megamenu"
                    ga4_event_param_1_name="element"
                    ga4_event_param_1_value={`Tier 2 - ${link.label}`}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
