'use client';

import { HTMLAttributes, useRef } from 'react';
import Link from 'next/link';
import Script from 'next/script';
import useClassNames from '~/helpers/hooks/useClassNames';

export interface TrustpilotProps extends HTMLAttributes<HTMLDivElement> {
  templateId: string;
  styleHeight?: string; // px
  styleWidth?: string; //px or percentage
  className?: string;
  tags?: string; // data-tags="Dining" data-tags="Living" data-tags="Bedroom"
  stars?: number; // data-stars="4,5"
  language?: string; // data-review-languages="en"
  schemaType?: string; // data-schema-type="Organization"
}

/**
 * Displays Trustpilot Widget which is a 3rd party script that creates an iframe.
 * Styling and templating is done directly on the Trustpilot site itself.
 * Only template type is required. Other details such as width and height will be auto filled if empty.
 */
export default function TrustpilotWidget({
  templateId,
  styleHeight,
  styleWidth,
  tags,
  stars,
  language,
  schemaType,
  ...props
}: TrustpilotProps): JSX.Element {
  let defaultDims = { height: '28px', width: '100%', className: '' };

  defaultDims = getDefaultDims(templateId);

  const height = styleHeight ? styleHeight : defaultDims.height;
  const width = styleWidth ? styleWidth : defaultDims.width;
  const className = props.className ? props.className : defaultDims.className;
  const trustpilotRef = useRef(null);

  const trustpilotClassNames = useClassNames(['trustpilot-widget', className]);

  return (
    <>
      <Script
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="lazyOnload"
        onReady={() => {
          if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(trustpilotRef.current, true);
          }
        }}
      />
      <div
        ref={trustpilotRef}
        style={{ height: height }}
        data-locale="en-GB"
        data-template-id={templateId}
        data-businessunit-id={process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID}
        data-style-height={height}
        data-style-width={width}
        data-theme="light"
        data-tags={tags || ''}
        data-stars={stars || ''}
        data-review-languages={language || ''}
        data-schema-type={schemaType || ''}
        {...props}
        className={trustpilotClassNames}
      >
        <Link href={process.env.NEXT_PUBLIC_TRUSTPILOT_REVIEW_URL as string} target="_blank" rel="noreferrer">
          Trustpilot
        </Link>
      </div>
    </>
  );
}

export function getDefaultDims(template: string) {
  switch (template) {
    case '539adbd6dec7e10e686debee': // GRID
      return { height: '500px', width: '100%', className: '' };
    case '5406e65db0d04a09e042d5fc': // HORIZONTAL
      return { height: '28px', width: '70%', className: 'trustpilot-bigger-view' };
    case '5419b6ffb0d04a076446a9af': // MICRO_COMBO
      return { height: '20px', width: '100%', className: 'trustpilot-smaller-view' };
    case '5419b732fbfb950b10de65e5': // MICRO_STAR
      return { height: '24px', width: '100%', className: '' };
    case '5419b637fa0340045cd0c936': // MICRO_TRUSTSCORE
      return { height: '20px', width: '100%', className: '' };
    case '53aa8807dec7e10d38f59f32': // MINI
      return { height: '150px', width: '100%', className: '' };
    case '54ad5defc6454f065c28af8b': // STANDARD
      return { height: '245px', width: '100%', className: '' };
    default:
      return { height: '28px', width: '100%', className: '' };
  }
}
