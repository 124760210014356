import { ComponentProps, useRef } from 'react';
import useVariantMainImage from '~/helpers/hooks/furniturechoice/use-variant-main-image';
import ContentfulImage from '../contentful-image';

interface VariantMainImageProps extends Omit<ComponentProps<typeof ContentfulImage>, 'src'> {
  sku: string;
  lazyLoadInView?: boolean;
}

export default function VariantMainImage({
  sku,
  lazyLoadInView = false,
  ...props
}: VariantMainImageProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const { data } = useVariantMainImage({ sku, ref: lazyLoadInView ? ref : undefined });

  return (
    <>
      {lazyLoadInView && <div ref={ref} />}
      <ContentfulImage src={data?.uri} alt={data?.alt} {...props} />
    </>
  );
}
