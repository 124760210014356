import { useEffect, useState } from 'react';
import { sdk } from '~/sdk';

const OTC_STRICTLY_NECESSARY = 'BG65';
const OTC_FUNCTIONAL = 'BG66';
const OTC_PERFORMANCE = 'BG67';
const OTC_ADVERTISING = 'BG68';

type ConsentPayload = {
  temporaryId: string | null;
  necessaryConsent: boolean;
  functionalConsent: boolean;
  performanceConsent: boolean;
  advertisingConsent: boolean;
};

async function saveConsentInSession(payload: ConsentPayload) {
  return await sdk.callAction<{ ok: boolean }>({
    actionName: 'furniturechoice-relewise/saveConsentInSession',
    payload,
  });
}

export const useCookieConsent = () => {
  const [consents, setConsents] = useState<string[]>([]);
  const [subjectId, setSubjectId] = useState<string | null>(null);

  const handleConsentUpdate = () => {
    const currentConsents = window.OnetrustActiveGroups?.split(',') ?? [];
    setConsents(currentConsents);

    const temporaryId = window.OneTrust?.getDataSubjectId() ?? null;
    setSubjectId(temporaryId);
    saveConsentInSession({
      temporaryId,
      necessaryConsent: currentConsents.includes(OTC_STRICTLY_NECESSARY),
      functionalConsent: currentConsents.includes(OTC_FUNCTIONAL),
      performanceConsent: currentConsents.includes(OTC_PERFORMANCE),
      advertisingConsent: currentConsents.includes(OTC_ADVERTISING),
    });
  };

  useEffect(() => {
    handleConsentUpdate();

    window.addEventListener('OneTrustGroupsUpdated', handleConsentUpdate);

    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', handleConsentUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    consents,
    subjectId,
    necessaryConsent: consents.includes(OTC_STRICTLY_NECESSARY),
    functionalConsent: consents.includes(OTC_FUNCTIONAL),
    performanceConsent: consents.includes(OTC_PERFORMANCE),
    advertisingConsent: consents.includes(OTC_ADVERTISING),
  };
};
