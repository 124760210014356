import { RefObject, useContext } from 'react';
import { FNCAsset } from 'shared/types/furniturechoice/asset';
import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { sdk } from 'sdk';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import useIsInView from '../use-is-in-view';

interface Args {
  sku: string;
  ref?: RefObject<HTMLElement>;
}

export default function useVariantMainImage({ sku, ref }: Args): SWRResponse<FNCAsset> {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const { hasBeenViewed } = useIsInView({ ref });

  return useSWRImmutable<FNCAsset>(
    (ref?.current && hasBeenViewed) || !ref ? `/action/furniturechoice-product/getMainImage/${sku}` : null,
    async () => {
      return executeWithProgress<FNCAsset>(async () => {
        const response = await sdk.callAction<FNCAsset>({
          actionName: 'furniturechoice-product/getMainImage',
          payload: { sku },
          skipQueue: true,
        });

        if (response.isError) {
          throw response.error;
        }

        return response.data;
      });
    },
  );
}
