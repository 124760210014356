import { HTMLAttributes } from 'react';

export interface GA4EventProps {
  ga4_event_name?: string;
  ga4_event_param_1_name?: string;
  ga4_event_param_1_value?: string;
  ga4_event_param_2_name?: string;
  ga4_event_param_2_value?: string;
  ga4_event_param_3_name?: string;
  ga4_event_param_3_value?: string;
  ga4_event_param_4_name?: string;
  ga4_event_param_4_value?: string;
  ga4_event_param_5_name?: string;
  ga4_event_param_5_value?: string;
}

export default function withGA4Event<T extends HTMLAttributes<HTMLElement>>(Component: React.ComponentType<T>) {
  return function WrappedComponent({ ...props }: T & GA4EventProps) {
    return <Component {...(props as T)} />;
  };
}
