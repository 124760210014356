import { ButtonHTMLAttributes, forwardRef, ForwardedRef } from 'react';
import { GA4EventProps } from '../with-ga4-event';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ children, ...props }: ButtonProps, ref?: ForwardedRef<HTMLButtonElement>) => (
  <button ref={ref} {...props}>
    {children}
  </button>
);
const ForwardButton = forwardRef(Button);

function GA4Button(
  { ...props }: ButtonHTMLAttributes<HTMLButtonElement> & GA4EventProps,
  ref?: ForwardedRef<HTMLButtonElement>,
) {
  return <ForwardButton ref={ref} {...props}></ForwardButton>;
}

const ForwardedGA4Button = forwardRef(GA4Button);

export default ForwardedGA4Button;
