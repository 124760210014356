import Link from '~/components/furniturechoice/link';
import { PrimarySubNavigation } from '../../../../types';

interface PrimaryProps {
  subNavigation: PrimarySubNavigation;
}

export default function Primary({ subNavigation }: PrimaryProps): JSX.Element {
  return (
    <div className="container">
      <div className="grid grid-cols-4 gap-9 px-3 xl:grid-cols-5">
        {subNavigation.groups.map((group) => (
          <div key={group.label} className="flex flex-col gap-y-4" data-testid="group">
            <p className="text-18 font-bold">{group.label}</p>

            <ul className="flex flex-col gap-y-2">
              {group.links.map((link) => (
                <li key={link.label}>
                  <Link
                    className="color-change-transition text-14 hover:text-salmon-5"
                    reference={link.reference}
                    data-testid="link"
                    ga4_event_name="navigation_megamenu"
                    ga4_event_param_1_name="element"
                    ga4_event_param_1_value={`Tier 2 - ${link.label}`}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
