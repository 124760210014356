import { Currency } from '@commercetools/frontend-sdk/lib/types/Currency';

interface LocalizationMapping {
  locale: string;
  currency: Currency;
  currencyCode: string;
  countryName: string;
  countryCode: string;
  countryKey: string;
}

const localizationMapper = {
  en: {
    locale: 'en_GB',
    currency: 'GBP',
    currencyCode: '£',
    countryCode: 'GB',
    countryName: 'United Kingdom',
    countryKey: 'gb',
  },
  de: {
    locale: 'de_DE',
    currency: 'EUR',
    currencyCode: '€',
    countryCode: 'DE',
    countryName: 'Germany',
    countryKey: 'de',
  },
} as Record<string, LocalizationMapping>;

export const getLocalizationInfo = (locale: string) => {
  if (!(locale in localizationMapper)) {
    //console.warn(
    //  `Invalid locale ${locale} provided. Possible values are ${Object.keys(localizationMapper).join(', ')}`,
    //);

    return localizationMapper.en;
  }

  return localizationMapper[locale as keyof typeof localizationMapper];
};
