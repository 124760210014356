import clsx from 'clsx';
import GA4Button from '~/components/furniturechoice/ga4-button';
import Link from '~/components/furniturechoice/link';
import isValidCoFeLink from '~/helpers/utils/furniturechoice/is-valid-co-fe-link';
import SubNavigation from './sub-navigation';
import { Footnote, Menu as MenuType } from '../../types';

export interface MenuProps {
  menu: MenuType;
  footnote: Footnote;
  isExpanded: boolean;
  onMenuClick(): void;
  onBackdropClick(): void;
}

export default function Menu({ menu, footnote, isExpanded, onMenuClick, onBackdropClick }: MenuProps): JSX.Element {
  if (menu.reference && isValidCoFeLink(menu.reference)) {
    return (
      <li className="h-5">
        <Link
          className={clsx('color-change-transition text-12 font-normal hover:text-salmon-5', {
            'text-red-2 lg:font-semibold': menu.theme === 'saleRed',
          })}
          data-name="mobile-nav-menu"
          data-testid="cta-link"
          reference={menu.reference}
          ga4_event_name="navigation_megamenu"
          ga4_event_param_1_name="element"
          ga4_event_param_1_value={`Tier 1 - ${menu.label}`}
        >
          {menu.label}
        </Link>
      </li>
    );
  }

  return (
    <li className="h-5">
      <GA4Button
        className={clsx(
          'color-change-transition relative text-12 font-normal hover:text-salmon-5',
          'data-[active="true"]:font-bold data-[active="true"]:text-salmon-4',
          'data-[active="true"]:after:absolute data-[active="true"]:after:inset-x-0 data-[active="true"]:after:bottom-[-11px] data-[active="true"]:after:border-b-[3px] data-[active="true"]:after:border-solid data-[active="true"]:after:border-salmon-4 data-[active="true"]:after:content-[""]',
          { 'text-red-2 lg:font-semibold': menu.theme === 'saleRed' },
        )}
        data-name="mobile-nav-menu"
        data-testid="cta-button"
        data-active={isExpanded}
        type="button"
        onClick={onMenuClick}
        ga4_event_name="navigation_megamenu"
        ga4_event_param_1_name="element"
        ga4_event_param_1_value={`Tier 1 - ${menu.label}`}
      >
        {menu.label}
      </GA4Button>

      <SubNavigation menu={menu} footnote={footnote} isExpanded={isExpanded} onNewsletterCtaClick={onBackdropClick} />
      <div
        className={clsx('absolute inset-x-0 bottom-0 -z-50 h-screen cursor-pointer bg-black opacity-75', {
          hidden: !isExpanded,
          'block translate-y-full': isExpanded,
        })}
        data-testid="backdrop"
        onClick={onBackdropClick}
      />
    </li>
  );
}
