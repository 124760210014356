import { useParams } from 'next/navigation';
import i18nConfig from '~/i18n.config';
import { getLocalizationInfo } from '~/project.config';

export const useResolvedLocalizedObject = (localized: object | string) => {
  const { locale: paramLocale } = useParams();
  const nextLocale = paramLocale || i18nConfig.defaultLocale;

  const { locale } = getLocalizationInfo(nextLocale);

  return localized[locale as keyof typeof localized] ?? localized ?? '';
};
