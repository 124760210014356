'use client';

import { useEffect, useRef } from 'react';
import { useCart } from '~/frontastic';
import { sdk } from '~/sdk';
import { useCookieConsent } from '~/utils/use-cookie-consent';
import { sendGA4TransactionEvent } from '../send-ga4-event';

interface TrackCartPayload {
  subtotal: {
    currency: string;
    amount: number;
  };
  lineItems: {
    productId: string;
    lineTotal: number;
    quantity: number;
  }[];
  cartName?: string;
}

async function trackCart(payload: TrackCartPayload) {
  return await sdk.callAction<{ ok: boolean }>({
    actionName: 'furniturechoice-relewise/trackCart',
    payload: payload,
  });
}

const useTrackCart = () => {
  const cart = useCart();
  const { functionalConsent } = useCookieConsent();
  const cartVersion = useRef<string | null>(null);

  useEffect(() => {
    // Prevent tracking if no consent is given
    if (!functionalConsent) {
      return;
    }

    // Prevent duplicate tracking
    if (cartVersion.current === cart.data?.cartVersion) {
      return;
    }

    // Prevent tracking if cart is empty
    if (cart.data?.lineItems?.length === 0) {
      return;
    }

    const subtotal = {
      currency: cart.data?.sum?.currencyCode ?? 'GBP',
      amount: cart.data?.sum?.centAmount ? cart.data?.sum.centAmount / 10 ** (cart.data?.sum?.fractionDigits || 0) : 0,
    };
    const lineItems =
      cart.data?.lineItems?.map((lineItem) => ({
        productId: lineItem.productId ?? '',
        lineTotal: lineItem.totalPrice?.centAmount
          ? lineItem.totalPrice.centAmount / 10 ** (lineItem.totalPrice.fractionDigits || 0)
          : 0,
        quantity: lineItem.count ?? 1,
      })) ?? [];

    // Track cart on Relewise
    trackCart({
      subtotal,
      lineItems,
      cartName: cart.data?.cartId,
    });

    // Track the view_cart event with 100ms delay to ensure that add_to_cart/remove_from_cart events are tracked first
    setTimeout(() => sendGA4TransactionEvent('view_cart', cart.data?.lineItems ?? []), 100);

    // Update the previous cart version reference to the current cart version
    cartVersion.current = cart.data?.cartVersion ?? null;
  }, [functionalConsent, cart.data]);
};

export default useTrackCart;
