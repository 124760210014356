import { HTMLAttributes, MouseEvent, startTransition, useContext } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { Reference } from '~/types/reference';
import withGA4Event from '../with-ga4-event';

interface LinkProps extends HTMLAttributes<HTMLAnchorElement>, Omit<NextLinkProps, 'href'> {
  href?: string;
  reference?: Reference;
}

function resolveHref(href?: string, reference?: Reference): string {
  if (reference) {
    return reference.type === 'link' ? reference.link : reference.pageFolder._url;
  }

  return href || '';
}

function Link({ href, reference, children, ...props }: LinkProps): JSX.Element {
  const { showProgressIndicator, hideProgressIndicator } = useContext(ProgressIndicatorContext);
  const router = useRouter();
  const pathname = usePathname();
  const finalHref = resolveHref(href, reference);
  const isOpeningInNewWindow = reference?.openInNewWindow || finalHref?.startsWith('http') || false;

  function handleClick(e: MouseEvent<HTMLAnchorElement>): void {
    if (!isOpeningInNewWindow && !(e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      showProgressIndicator();

      startTransition(() => {
        router.push(finalHref);
        hideProgressIndicator();
      });
    }
  }

  return (
    <NextLink
      href={finalHref}
      target={isOpeningInNewWindow ? '_blank' : undefined}
      rel={isOpeningInNewWindow ? 'noreferrer noopener' : undefined}
      onClick={handleClick}
      data-active={pathname.replace('/en', '').startsWith(finalHref)}
      {...props}
    >
      {children}
    </NextLink>
  );
}

const GA4Link = withGA4Event(Link);
export default GA4Link;
